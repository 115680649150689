<template>
  <div class="container-page">
    <div>
      <p class="title-inner">Операции</p>
      <div class="mb-16">
        <ButtonStock class="mr-2" title="Создать" @click="editCard(0)" />
        <ButtonStock title="Экспорт" @click="exportExcel()" :disabled="isExport" />
      </div>
      <div class="mb-4">
        <DefaultFilter :filterValue="filter" @loadFilter="onLoadFilter" />
      </div>
      <div>
        <div class="table-container mb-16 js-table-arrow">
          <table class="stockTable">
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th>Название</th>
                <th>Статус</th>
                <th>Дата создания</th>
                <th>Дата изменения</th>
              </tr>
              <tr>
                <th class="filter"></th>
                <th class="filter"></th>
                <th class="filter">
                  <FilterInput v-model="filter.name" placeholder="Найти..." v-on:valueChangedDebounced="changeFilter" />
                </th>
                <th class="filter">
                  <FilterActiveInput
                    v-model="filter.isActive"
                    placeholder="(Все)"
                    v-on:valueChangedDebounced="changeFilter"
                  />
                </th>
                <th class="filter date-w-135">
                  <FilterInput
                    type="date"
                    modClass="filter-small"
                    v-model="filter.createdStart"
                    v-on:valueChangedDebounced="changeFilter"
                    :upperLimit="upperLimitCreationStart"
                    dateIcon
                  />
                  <FilterInput
                    type="date"
                    modClass="filter-small"
                    v-model="filter.createdEnd"
                    v-on:valueChangedDebounced="changeFilter"
                    :upperLimit="upperLimitEnd"
                    :lowerLimit="lowerLimitCreationEnd"
                    dateIcon
                  />
                </th>
                <th class="filter date-w-135">
                  <FilterInput
                    type="date"
                    modClass="filter-small"
                    v-model="filter.modifiedStart"
                    v-on:valueChangedDebounced="changeFilter"
                    :upperLimit="upperLimitModifiedStart"
                    dateIcon
                  />
                  <FilterInput
                    type="date"
                    modClass="filter-small"
                    v-model="filter.modifiedEnd"
                    v-on:valueChangedDebounced="changeFilter"
                    :upperLimit="upperLimitEnd"
                    :lowerLimit="lowerLimitModifiedEnd"
                    dateIcon
                  />
                </th>
              </tr>
            </thead>
            <tbody v-if="page.items?.length && !isLoading">
              <tr v-for="item in page.items" :key="item.id" @dblclick="editCard(item.id)">
                <td>
                  <IconComponent @click="editCard(item.id)" name="mode" />
                </td>
                <td>
                  <IconComponent @click="deleteCard(item.id)" name="delete" />
                </td>
                <td>{{ item.name }}</td>
                <td>{{ item.isActive ? 'действ.' : 'не действ.' }}</td>
                <td class="table-sort__td column-date-creation">
                  <p>
                    {{ $momentFormat(item?.created, DATE_FORMATS['DD.MM.YYYY']) }}
                    <br />
                    {{ $momentFormat(item?.created, DATE_FORMATS['HH:mm']) }}
                  </p>
                </td>
                <td class="table-sort__td column-date-change">
                  <p>
                    {{ $momentFormat(item?.modified, DATE_FORMATS['DD.MM.YYYY']) }}
                    <br />
                    {{ $momentFormat(item?.modified, DATE_FORMATS['HH:mm']) }}
                  </p>
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="isLoading">
              <tr>
                <td class="text-center" colspan="6">Загрузка...</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="text-center" colspan="6">Нет данных.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="page.pagesTotal > 1">
          <Pagination
            :page-count="page.pagesTotal"
            :current-page="page.pageNumber"
            @change="onPageChange($event, false)"
            @more="onPageChange($event, true)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DefaultFilter from '@/common/components/DefaultFilter';
  import FilterActiveInput from '@/common/components/FilterActiveInput';
  import FilterInput from '@/common/components/FilterInput';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';

  import IconComponent from '../../../common/ui/IconComponent';
  import ButtonStock from '../../../components/buttons/ButtonStock';
  import API from '../api/step';
  import dateFormat from '../mixin/dateFormat.js';

  export default {
    name: 'Steps',
    components: { IconComponent, ButtonStock, FilterInput, FilterActiveInput, DefaultFilter },
    mixins: [filtermanager, dateFormat],
    data() {
      return {
        page: {},
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
          isTemplate: true,
        },
        isLoading: false,
        filter: {
          name: null,
          isActive: null,
          createdStart: '',
          createdEnd: '',
          modifiedStart: '',
          modifiedEnd: '',
        },
        isExport: false,
        DATE_FORMATS: Constants.DATE_FORMATS,
      };
    },
    created() {
      this.loadFilter().then((response) => {
        let filter = response;
        if (filter) {
          this.filter = filter;
        }
        this.loadPage();
      });
    },
    methods: {
      loadPage(append) {
        this.isLoading = true;
        const request = Object.assign(this.filter, this.request);
        API.search(request)
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.error(error?.response?.data);
          });
      },
      editCard(id) {
        this.$router.push({ name: 'StepCard', params: { id: id, action: 'edit' } });
      },
      deleteCard(id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            API.delete(id)
              .then((response) => {
                console.log(response);
                Constants.alert.fire('Удаление', 'Запись удалена', 'success');
                this.loadPage();
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Удаление',
                  error.response && error.response.data ? error.response.data : 'Запись не удалена',
                  'error',
                );
              });
          }
        });
      },
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.saveFilter(this.filter);
        this.loadPage(false);
      },
      onLoadFilter(item) {
        this.filter = item;
        this.changeFilter();
      },
      exportExcel() {
        this.isExport = true;
        const request = Object.assign(this.filter, this.request);
        API.exportExcel(request)
          .then(() => {
            this.isExport = false;
          })
          .catch(() => {
            this.isExport = false;
          });
      },
    },
  };
</script>

<style scoped>
  .stockTable {
    min-height: 65vh;
  }
</style>
