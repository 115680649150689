import axios from 'axios';

import Utils from '@/common/utils';

export default {
  search: function (request) {
    return axios.get('/api/step/search', { params: request });
  },
  find: function (id) {
    return axios.get(`/api/step/find/${id}`);
  },
  update: function (data) {
    if (data.id > 0) return axios.put(`/api/step/${data.id}`, data);
    else return axios.post('/api/step', data);
  },
  delete: function (id) {
    return axios.delete(`/api/step/${id}`);
  },
  exportExcel: function (request) {
    return Utils.downloadFile('/api/step/exportExcel', 'StepExport.xlsx', request);
  },
};
